<template>
  <Page
    v-bind:heading="'Please fully complete the questionnaire...'"
    v-bind:background="'bg-basket'"
    v-bind:questionOptions="true"
  >
    <template v-slot:contents>
      <div class="container will-incomplete">
        <div class="row pb60">
          <div
            class="col-lg-6 col-lg-offset-3 col-md-8 col-md-offset-2 col-sm-12 col-xs-12"
          >
            <ul class="list">
              <li
                v-bind:class="{
                  incomplete: !profile.profileCompleted
                }"
              >
                <i
                  v-bind:class="{
                    'fa fa-check c-green': profile.profileCompleted,
                    'fa fa-times c-primary': !profile.profileCompleted
                  }"
                ></i>
                <router-link to="/profile" tag="a"> Profile </router-link>
              </li>
              <li
                v-if="children.childrenRequired"
                v-bind:class="{
                  incomplete: !children.childrenCompleted
                }"
              >
                <i
                  v-bind:class="{
                    'fa fa-check c-green': children.childrenCompleted,
                    'fa fa-times c-primary': !children.childrenCompleted
                  }"
                ></i>
                <router-link to="/children" tag="a"> Children </router-link>
              </li>
              <li
                v-bind:class="{
                  incomplete: !executors.executorsCompleted
                }"
              >
                <i
                  v-bind:class="{
                    'fa fa-check c-green': executors.executorsCompleted,
                    'fa fa-times c-primary': !executors.executorsCompleted
                  }"
                ></i>
                <router-link to="/executors" tag="a"> Executors </router-link>
              </li>
              <li
                v-if="trustees.trusteesRequired"
                v-bind:class="{
                  incomplete: !trustees.trusteesCompleted
                }"
              >
                <i
                  v-bind:class="{
                    'fa fa-check c-green': trustees.trusteesCompleted,
                    'fa fa-times c-primary': !trustees.trusteesCompleted
                  }"
                ></i>
                <router-link to="/trustees" tag="a"> Trustees </router-link>
              </li>
              <li
                v-if="guardians.guardiansRequired"
                v-bind:class="{
                  incomplete: !guardians.guardiansCompleted
                }"
              >
                <i
                  v-bind:class="{
                    'fa fa-check c-green': guardians.guardiansCompleted,
                    'fa fa-times c-primary': !guardians.guardiansCompleted
                  }"
                ></i>
                <router-link to="/" tag="a"> Guardians </router-link>
              </li>

              <li
                v-bind:class="{
                  incomplete: !distribution.distributionCompleted
                }"
              >
                <i
                  v-bind:class="{
                    'fa fa-check c-green': distribution.distributionCompleted,
                    'fa fa-times c-primary': !distribution.distributionCompleted
                  }"
                ></i>
                <router-link to="/distribution" tag="a">
                  Distribution
                </router-link>
              </li>
              <li
                v-bind:class="{
                  incomplete: !gifts.giftsCompleted
                }"
              >
                <i
                  v-bind:class="{
                    'fa fa-check c-green': gifts.giftsCompleted,
                    'fa fa-times c-primary': !gifts.giftsCompleted
                  }"
                ></i>
                <router-link to="/gifts" tag="a"> Gifts </router-link>
              </li>
              <li
                v-bind:class="{
                  incomplete: !funeral.funeralCompleted
                }"
              >
                <i
                  v-bind:class="{
                    'fa fa-check c-green': funeral.funeralCompleted,
                    'fa fa-times c-primary': !funeral.funeralCompleted
                  }"
                ></i>
                <router-link to="/funeral" tag="a"> Funeral </router-link>
              </li>
              <li
                v-if="pets.petsRequired"
                v-bind:class="{
                  incomplete: !pets.petsCompleted
                }"
              >
                <i
                  v-bind:class="{
                    'fa fa-check c-green': pets.petsCompleted,
                    'fa fa-times c-primary': !pets.petsCompleted
                  }"
                ></i>
                <router-link to="/pets" tag="a"> Pets </router-link>
              </li>

              <li
                v-bind:class="{
                  incomplete: !assets.assetsCompleted
                }"
              >
                <i
                  v-bind:class="{
                    'fa fa-check c-green': assets.assetsCompleted,
                    'fa fa-times c-primary': !assets.assetsCompleted
                  }"
                ></i>
                <router-link to="/assets" tag="a"> Assets </router-link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </template>
  </Page>
</template>

<script>
import Page from '@/common/ui/Page'

export default {
  name: 'WillNotReadyPage',
  components: {
    Page
  },
  created() {
    if (this.$store.getters.progressWillReady === true)
      this.$router.push('/create_will').catch((error) => {
        console.log(error)
      })
  },
  computed: {
    children() {
      return this.$store.getters.progressChildren
    },
    guardians() {
      return this.$store.getters.progressGuardians
    },
    trustees() {
      return this.$store.getters.progressTrustees
    },
    executors() {
      return this.$store.getters.progressExecutors
    },
    funeral() {
      return this.$store.getters.progressFuneral
    },
    profile() {
      return this.$store.getters.progressProfile
    },
    gifts() {
      return this.$store.getters.progressGifts
    },
    pets() {
      return this.$store.getters.progressPets
    },
    assets() {
      return this.$store.getters.progressAssets
    },
    distribution() {
      return this.$store.getters.progressDistribution
    }
  },
  methods: {
    setYes() {
      this.$store.commit('checkoutBasket', {
        package: true
      })
    },
    setNo() {
      this.$store.commit('checkoutBasket', {
        package: false
      })
    }
  }
}
</script>
