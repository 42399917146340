var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Page',{attrs:{"heading":'Please fully complete the questionnaire...',"background":'bg-basket',"questionOptions":true},scopedSlots:_vm._u([{key:"contents",fn:function(){return [_c('div',{staticClass:"container will-incomplete"},[_c('div',{staticClass:"row pb60"},[_c('div',{staticClass:"col-lg-6 col-lg-offset-3 col-md-8 col-md-offset-2 col-sm-12 col-xs-12"},[_c('ul',{staticClass:"list"},[_c('li',{class:{
                incomplete: !_vm.profile.profileCompleted
              }},[_c('i',{class:{
                  'fa fa-check c-green': _vm.profile.profileCompleted,
                  'fa fa-times c-primary': !_vm.profile.profileCompleted
                }}),_c('router-link',{attrs:{"to":"/profile","tag":"a"}},[_vm._v(" Profile ")])],1),(_vm.children.childrenRequired)?_c('li',{class:{
                incomplete: !_vm.children.childrenCompleted
              }},[_c('i',{class:{
                  'fa fa-check c-green': _vm.children.childrenCompleted,
                  'fa fa-times c-primary': !_vm.children.childrenCompleted
                }}),_c('router-link',{attrs:{"to":"/children","tag":"a"}},[_vm._v(" Children ")])],1):_vm._e(),_c('li',{class:{
                incomplete: !_vm.executors.executorsCompleted
              }},[_c('i',{class:{
                  'fa fa-check c-green': _vm.executors.executorsCompleted,
                  'fa fa-times c-primary': !_vm.executors.executorsCompleted
                }}),_c('router-link',{attrs:{"to":"/executors","tag":"a"}},[_vm._v(" Executors ")])],1),(_vm.trustees.trusteesRequired)?_c('li',{class:{
                incomplete: !_vm.trustees.trusteesCompleted
              }},[_c('i',{class:{
                  'fa fa-check c-green': _vm.trustees.trusteesCompleted,
                  'fa fa-times c-primary': !_vm.trustees.trusteesCompleted
                }}),_c('router-link',{attrs:{"to":"/trustees","tag":"a"}},[_vm._v(" Trustees ")])],1):_vm._e(),(_vm.guardians.guardiansRequired)?_c('li',{class:{
                incomplete: !_vm.guardians.guardiansCompleted
              }},[_c('i',{class:{
                  'fa fa-check c-green': _vm.guardians.guardiansCompleted,
                  'fa fa-times c-primary': !_vm.guardians.guardiansCompleted
                }}),_c('router-link',{attrs:{"to":"/","tag":"a"}},[_vm._v(" Guardians ")])],1):_vm._e(),_c('li',{class:{
                incomplete: !_vm.distribution.distributionCompleted
              }},[_c('i',{class:{
                  'fa fa-check c-green': _vm.distribution.distributionCompleted,
                  'fa fa-times c-primary': !_vm.distribution.distributionCompleted
                }}),_c('router-link',{attrs:{"to":"/distribution","tag":"a"}},[_vm._v(" Distribution ")])],1),_c('li',{class:{
                incomplete: !_vm.gifts.giftsCompleted
              }},[_c('i',{class:{
                  'fa fa-check c-green': _vm.gifts.giftsCompleted,
                  'fa fa-times c-primary': !_vm.gifts.giftsCompleted
                }}),_c('router-link',{attrs:{"to":"/gifts","tag":"a"}},[_vm._v(" Gifts ")])],1),_c('li',{class:{
                incomplete: !_vm.funeral.funeralCompleted
              }},[_c('i',{class:{
                  'fa fa-check c-green': _vm.funeral.funeralCompleted,
                  'fa fa-times c-primary': !_vm.funeral.funeralCompleted
                }}),_c('router-link',{attrs:{"to":"/funeral","tag":"a"}},[_vm._v(" Funeral ")])],1),(_vm.pets.petsRequired)?_c('li',{class:{
                incomplete: !_vm.pets.petsCompleted
              }},[_c('i',{class:{
                  'fa fa-check c-green': _vm.pets.petsCompleted,
                  'fa fa-times c-primary': !_vm.pets.petsCompleted
                }}),_c('router-link',{attrs:{"to":"/pets","tag":"a"}},[_vm._v(" Pets ")])],1):_vm._e(),_c('li',{class:{
                incomplete: !_vm.assets.assetsCompleted
              }},[_c('i',{class:{
                  'fa fa-check c-green': _vm.assets.assetsCompleted,
                  'fa fa-times c-primary': !_vm.assets.assetsCompleted
                }}),_c('router-link',{attrs:{"to":"/assets","tag":"a"}},[_vm._v(" Assets ")])],1)])])])])]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }